<template>
  <div>
    <h3>Deal Information</h3>
    <p v-if="!loading && viewable">
      Full address and application details will be provided upon interest in
      pursuing lending opportunity.
    </p>
    <p v-if="loading && viewable">Loading...</p>
    <br />
    <div v-if="!loading">
      <Read_Deal_Summary
        :company="company"
        :deal_summary_data="deal_summary_data"
        @getDealInfo="getDealInfo"
        view_type="summary_link_token"
        :summary_link_token="summary_link_token"
      />
    </div>
    <div v-if="!loading && !viewable">
      <v-alert type="warning"
        >This lending opportunity is not currently available for
        viewing.</v-alert
      >
    </div>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

import Read_Deal_Summary from "@/components/Read_Deal_Summary";

export default {
  props: ["deal_summary_uuid"],

  components: {
    Read_Deal_Summary,
  },

  data() {
    return {
      summary_link_token: null,
      deal_summary_data: {},
      company: {},
      loading: true,
      viewable: false
    };
  },
  methods: {
    async getDealInfo() {
      try {
        let dealResponse = await API().get(
          `/api/user_read_deal_summary_link/${this.deal_summary_uuid}/?summary_link_token=${this.summary_link_token}`
        );
        console.log(dealResponse);
        this.deal_summary_data = dealResponse.data.deal_summary_data;
        this.company = dealResponse.data.summary_link_token_information.company;


        this.deal_summary_data.summary_offers.forEach(offer => {
          if(offer.company.id == parseInt(this.company.id) ){
            offer.my_offer = true
          } else {
            offer.my_offer = false
          }
        });

        this.viewable = true;
        this.loading = false;
      } catch (error) {
        this.viewable = false;
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.summary_link_token = this.$route.query.summary_link_token;
    console.log();
    if (this.summary_link_token) {
      await this.getDealInfo();
    }
  },
  filters: {
    ...filters,
  },
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
  .row-bg-clear {
    background-color: transparent !important;
  }
}
</style>